















import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/admin/components/PageHeader.vue";
import __ from "@/shared/helpers/__";
import { RoleLevel } from "@/shared/modules/role/role.model";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import User from "@/shared/modules/user/user.model";
import { AuthGetters } from "@/shared/store/auth/auth.getters";
import FormField from "@/shared/components/form/FormField.vue";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import UserCourseStatistic from "@/shared/components/UserCourseStatistic.vue";
import RoleCan from "@/shared/components/RoleCan.vue";

@Component({
  components: { PageHeader, FormField, UserCourseStatistic, RoleCan },
  methods: { __ }
})
export default class CourseStatistics extends Vue {
  RoleLevel = RoleLevel
  employeeSelect: SearchableField | null = null
  employeeSelectValue = this.user.uuid

  created() {
    this.employeeSelect = new SearchableField()
      .setKey('userUuid')
      .setTitle(__('company.views.courses.statistics.employee'))
      .loadItems({
        endpoint: `company/${this.company.slug}/employees`,
        value: 'uuid',
        title: 'full_name',
        perPage: 1000,
      })
      .setHideDetails(true)
      .setDense(true)
      .setValue(this.employeeSelectValue)
      .setOnChange((value) => this.employeeSelectValue = value)
  }


  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}
